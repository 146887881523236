import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getEmailLogListUrl, getEmailTemplateListUrl } from "@tager/admin-mail";

type LinkKey =
  | "HOME"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "EMAIL_TEMPLATE_LIST"
  | "EMAIL_LOG_LIST";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },

  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },

  EMAIL_TEMPLATE_LIST: {
    url: getEmailTemplateListUrl(),
    text: "Шаблоны",
  },
  EMAIL_LOG_LIST: {
    url: getEmailLogListUrl(),
    text: "Логи",
  },
});
