
import { computed, defineComponent, SetupContext } from "vue";

import { NavigationGrid, type NavigationGridItem } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: "E-Mail уведомления",
          linkList: [
            links.value.EMAIL_TEMPLATE_LIST,
            links.value.EMAIL_LOG_LIST,
          ],
        },

        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
